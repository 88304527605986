<template>
  <div class="game">
    <header-m></header-m>
    <div class="tab-box">
      <ul>
        <li :class="[activeName == 'first' ? 'active' : '']" @click="changeTab('first')">
          传奇系列
        </li>
        <li :class="[activeName == 'second' ? 'active' : '']" @click="changeTab('second')">
          武侠系列
        </li>
        <li :class="[activeName == 'third' ? 'active' : '']" @click="changeTab('third')">
          其他游戏
        </li>
      </ul>
    </div>
    <div class="tab-content">
      <div v-show="activeName == 'first' ? true : false">
        <img class="img-left" src="../assets/img/game_left01.png" alt="" />
        <div>
          <p>
            《雄霸传奇》由雄霸网络出品，以精致的建模、炫酷的技能以及多处地图细节，为玩家搭建了一个耐玩好看的东方魔幻世界。游戏内装备强力，玩法丰富，人气火爆。玩家可通过练级、转生、强化装备等方式提升战力，还可以组建帮会参加争夺战、跨服争霸等丰富趣味玩法，与兄弟并肩作战，挥洒热血，驰骋沙场，让玩家真正体验到纵横大陆的畅快感。
          </p>
        </div>
        <img src="../assets/img/game_list01.jpg" alt="" />
      </div>
      <div v-show="activeName == 'second' ? true : false">
        <img src="../assets/img/game_left02.png" alt="" />
        <div>
          <p>
            《雄霸江湖》由雄霸网络出品，舞台设置在百花齐放的武侠世界，以唯美古风背景，交织门派纷争和江湖缠斗，为玩家构建了一个行侠仗义，快意恩仇，刀光剑影的江湖，门派独具特色，技能简单华丽，阵营随心挑选，一切只为给玩家最纯粹的武侠体验。各路势力交锋，抵抗匈奴入侵，切磋论剑擂台决战，金兰情缘仗剑天涯，谁又能百战成名雄霸江湖？
          </p>
        </div>
        <img src="../assets/img/game_list02.jpg" alt="" />
      </div>
      <div v-show="activeName == 'third' ? true : false">
        <img src="../assets/img/game_left03.png" alt="" />
        <div>
          <p>
            《雄霸三国》由雄霸网络出品，以荡气回肠的三国时期为故事背景所制作的大地图即时国战大策略手游，游戏以真实历史地图为蓝本，再现三国鼎立之风云局势，玩家将扮演这乱世中的一名诸侯，在群雄割据的领土争夺中，招募培养名将名士，训练升级特色兵种，谱写属于自己的三国新篇章，业内独创武将羁绊玩法，与心仪的武将结为义兄，组建全明星阵容，共谋天下，三国霸业，指日可待！
          </p>
        </div>
        <img src="../assets/img/game_list03.jpg" alt="" />
      </div>
    </div>
    <footer-content></footer-content>
  </div>
</template>

<script>
// import "@/assets/js/flexible";
import HeaderM from "../components/HeaderM.vue";
import FooterContent from "@/components/FooterContent.vue";
import { isMobile } from '@/utils/util'
export default {
  name: "GameM",
  components: {
    HeaderM,
    FooterContent
  },
  data() {
    return {
      activeName: "first"
    };
  },
  created() {},
  mounted() {
    if (isMobile()) {
      // alert("手机端");
      // this.$router.replace('/gamem');
    } else {
      // alert("pc端");
      this.$router.replace('/game');
    }
  },
  methods: {
    changeTab(type) {
      this.activeName = type;
    }
  }
};
</script>

<style lang="scss" scoped>
.game {
  .tab-box {
    position: fixed;
    left: 0;
    top: 60px;
    z-index: 5;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    ul {
      display: flex;
      justify-content: space-between;
      list-style: none;
      padding: 0 40px;
      margin: 0;
      height: 60px;
      border-bottom: 1px solid #f5f5f5;
      li {
        text-align: center;
        font-size: 16px;
        line-height: 60px;
        color: #555;
      }
      .active {
        color: #409eff;
        border-bottom: 1px solid #409eff;
      }
    }
  }
  .tab-content {
    margin-top: 120px;
    img {
      display: block;
      width: 100%;
      margin-top: 20px;
    }
    p {
      font-size: 16px;
      width: 100%;
      padding: 0 20px;
      margin: 0;
      color: #888;
      text-align: justify;
      box-sizing: border-box;
    }
  }
}
</style>
